@import 'src/styles/colorVariables';
.ghg-closing-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    img {
      width: 107px;
      height: 24px;
    }
    .divider-line {
      height: 44px;
      width: 1px;
      background-color: $primary-color;
      margin-left: 20px;
      margin-right: 16px;
    }
    h1 {
      margin-bottom: 0px !important;
      margin-top: 0px !important;
    }
    h1.name-icon-big-font {
      margin-right: 5px;
    }
  }
}
