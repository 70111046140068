@import 'src/styles/colorVariables';
@import 'src/styles/fontClasses';

#headway-badge-trigger {
  position: relative;
  z-index: 1;
}

#HW_badge_cont {
  position: absolute;
  top: 0;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}

#HW_badge {
  @extend .font-12, .weight-600, .mulish;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  left: calc(100% - 12px);
  top: -6px;
  width: 18px;
  height: 18px;
}

.HW_badge.HW_visible {
  background: $notification-color !important;
}

.HW_badge.HW_softHidden,
.HW_badge {
  background: transparent !important;
}

#headway-badge-container {
  position: relative;
  z-index: 1;
}
