.objective-title {
  margin-left: 1em;
  margin-top: 1.5rem;
}

.objective-wrapper-sbti {
  padding: 1em;
  margin: 1em;

  h3 {
    font-size: 11px;
    margin-bottom: 0;
  }
}

.objective-data-sbti {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.4rem;
  display: flex;
  flex-direction: column;
  margin: 0;
  .headline4-font {
    font-size: 13px;
  }

  .objective {
    h4,
    p {
      margin: 0.25rem 0;
    }

    p {
      font-size: 10px;
    }

    h4 {
      background: linear-gradient(180deg, #225e66 0%, rgba(56, 207, 217, 0.91) 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }

  .objective:nth-child(2n) {
    width: 45%;
  }
}

.objective-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
