@import 'src/styles/colorVariables';
@import 'src/styles/fontClasses';

.purchases {
  width: 100%;
  height: 100%;
  margin-right: 12px;
  display: grid;
  grid-template-rows: 4.5rem 0.3fr 1fr;
  gap: 1rem;

  .calendar-wrapper {
    left: 75%;
  }

  &__header {
    width: 80%;
    h3 {
      margin-top: 17px;
      margin-bottom: 8px;
    }
  }
  &__body {
    display: flex;

    flex-wrap: wrap;
    width: 100%;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    @media (max-width: 1000px) {
      flex-direction: column;
    }
    .purchases-card {
      margin: 10px;
      padding: 13px 13px 18px 13px;
      border-width: 1px;
      border-style: dashed;
      border-radius: 5px;
      box-sizing: border-box;
      width: calc(100% - 20px);

      h1 {
        margin: 0 auto 6px auto;
        text-align: center;
      }

      p {
        margin: 0 auto 16px auto;
        text-align: center;
      }

      .buttons {
        display: flex;
        justify-content: center;
        button {
          width: auto !important;
          margin-right: 8px;
        }
        button:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.new-purchase,
.edit-purchase {
  height: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    margin-top: 0;
    margin-bottom: 8px;
  }
  h2 {
    margin-top: 0;
    margin-bottom: 48px;
  }
  .form-wrapper {
    margin-bottom: 42px;
    .input-element {
      .input {
        margin-bottom: 8px;
      }
    }
  }
}

.suppliers-notification-badge {
  position: relative;
  display: flex;
  align-items: center;
  &__active {
    padding-right: calc(1rem + 5px); // 1rem for the badge + 5px for the gap
  }
  .badge {
    @extend .font-12, .weight-600, .mulish;
    position: absolute;
    left: calc(100% - 1rem); // 100% of the parent width - 1rem for the badge width
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 0.5rem;
    background-color: $notification-color;
    color: white;
    height: 1rem;
    padding-inline: 0.25rem;
    border-radius: 20px;
  }
}
