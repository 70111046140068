@import '../../../../styles/colorVariables.scss';

.project-image-wrapper {
  overflow: hidden;
  max-width: 100%; /* Make sure the wrapper doesn't exceed its parent's width */
  width: 100%; /* Set the image's maximum width to 100% of the wrapper */

  display: flex;
  justify-content: center;
  align-items: center;
}

.project-image {
  position: relative;
  max-width: 100%; /* Set the image's maximum width to 100% of the wrapper */
  object-fit: contain;
}

.ods-cards {
  position: absolute;
  right: 0;
  margin: 0.5rem;
  gap: 0.5rem;
  display: flex;
}

.center-wrapper-offset {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 1rem;
  margin: 1rem 0;
}

.title-text {
  margin: 0;
}

.subtitle-text {
  margin: 0 1.5rem;
}

.span-wrapper {
  display: flex;
  gap: 0.5rem;
  margin: 0;
}

.wrapper-lateral-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.5rem;
  height: 100%;
}

.center-second-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  gap: 0.5rem;
  height: 100%;
}

.second-card-title {
  color: $white;
}

.alternative-text {
  color: $secondary-color;
}
