@import 'fontVariables';

// Font sizes

@for $i from 0 through 19 {
  .font-#{8+($i * 2)} {
    font-size: #{8 + ($i * 2)}px;
    letter-spacing: -0.03em !important;
    line-height: 120% !important;
  }
}

.font-5 {
  // Only case for 5px font size used in PoweredByDcycle.tsx
  font-size: 5px;
}
// Common classes

.semibold-font {
  font-family: $font-semibold !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 120% !important;
}
.regular-font {
  font-family: $font-regular;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.mulish {
  font-family: $font-regular;
  letter-spacing: -0.03em;
  line-height: 122%;
}

// Headline Classes
.headline1-big-font {
  @extend .regular-font;
  font-size: 62px;
}

.headline1-big-font-bolder {
  @extend .semibold-font;
  font-size: 62px;
}

.headline1-font {
  @extend .semibold-font;
  font-size: 48px;

  @media (max-width: 768px) {
    font-size: 32px;
  }
}

.headline2-font {
  @extend .semibold-font;

  font-size: 32px;
}

.headline3-font {
  @extend .semibold-font;

  font-size: 20px;
}

.headline4-font {
  @extend .semibold-font;

  font-size: 14px;
}

.headline5-font {
  @extend .semibold-font;

  font-size: 10px;
}

// Subtitle Classes

.subtitle1-font {
  @extend .regular-font;
  font-size: 20px;
}

.subtitle2-font {
  @extend .regular-font;
  font-size: 16px;
}

.subtitle3-font {
  @extend .regular-font;
  font-size: 12px;
}

// Body Classes

.body1-font {
  @extend .regular-font;
  font-size: 10px;
}
.body1-bold-font {
  @extend .semibold-font;
  font-size: 10px !important;
}

.body2-font {
  @extend .semibold-font;

  font-size: 10px;
}

.body3-font {
  @extend .regular-font;
  font-size: 10px;
}

// Inputs

.input-font {
  @extend .regular-font;

  font-size: 14px;
}
.input-small-font {
  @extend .regular-font;

  font-size: 12px;
}
.input-smaller-font {
  @extend .regular-font;

  font-size: 10px;
}

.input-label-font {
  @extend .semibold-font;

  font-size: 12px;
}

// Spans
.span-font {
  @extend .regular-font;

  font-size: 14px;
}

.span-bold-font {
  @extend .semibold-font;

  font-size: 14px;
}

.span-small-font {
  @extend .regular-font;

  font-size: 7px;
}

// Error font
.error-font {
  @extend .semibold-font;

  font-size: 10px;
}

// Card font
.small-card-font {
  @extend .semibold-font;

  font-size: 12px;
}
.select-card-font {
  @extend .semibold-font;

  font-size: 12px;
}

.select-card-small-font {
  @extend .semibold-font;

  font-size: 10px;
}

.report-card-font {
  @extend .semibold-font;

  font-size: 12px;
}
.description-card-font {
  @extend .regular-font;

  font-size: 12px;
}

.tooltip-font {
  @extend .regular-font;
  font-size: 12px;
}

.tooltip-chart-font {
  @extend .regular-font;
  font-size: 10px;
}

// Checkbox
.checkbox-font {
  @extend .regular-font;
  font-size: 10px;
}

// CO₂ dashboard
.co2-dashboard-font {
  @extend .semibold-font;
  font-size: 20px;
}

.co2-dashboard-font-small {
  @extend .semibold-font;
  font-size: 18px;
}

.co2-offset-font {
  @extend .semibold-font;

  font-size: 16px;
}

.co2-offset-improve-font {
  @extend .semibold-font;

  font-size: 14px;
}

.co2-offset-unit-font {
  @extend .regular-font;
  font-size: 10px;
}

// Tags
.tag-font {
  @extend .semibold-font;

  font-size: 10px;
}

.tag-smaller-font {
  @extend .regular-font;

  font-size: 10px;
}

.tag-smallest-font {
  @extend .regular-font;

  font-size: 10px !important;
}
.tag-bigger-font {
  @extend .semibold-font;

  font-size: 12px;
}

// Percentage
.percentage-big-font,
.register-step-title-font {
  @extend .semibold-font;

  font-size: 24px;
}

//Name icon
.name-icon-big-font {
  @extend .regular-font;

  font-size: 24px;
}
.name-icon-medium-font {
  @extend .regular-font;

  font-size: 12px;
}
// input file label size
.input-file-font {
  @extend .regular-font;
  font-size: 12px;
}

.side-menu-section-font {
  @extend .semibold-font;
  font-size: 12px;
}

.side-menu-subsection-font {
  @extend .regular-font;
  font-size: 12px;
}

// New font classes

.font-body-b1-r {
  @extend .font-14, .weight-400, .mulish;
}

.font-body-b1-sb {
  @extend .font-14, .weight-600, .mulish;
}

.font-body-b2-sb {
  @extend .font-12, .weight-600, .mulish;
}

.font-body-b2-r {
  @extend .font-12, .weight-400, .mulish;
}

.font-headings-h2-sb {
  @extend .font-32, .weight-600, .mulish;
}

.font-headings-h3-sb {
  @extend .font-24, .weight-600, .mulish;
}

.font-headings-h4-sb {
  @extend .font-14, .weight-600, .mulish;
}

.font-headings-h4-sb__dep {
  @extend .font-20, .weight-600, .mulish;
}
