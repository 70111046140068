// Google Font Mulish
// @import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap');

// Define Weights
$weights: ('ExtraLight': 100, 'Light': 200, 'Regular': 400, 'Medium': 500, 'SemiBold': 600, 'Bold': 700, 'ExtraBold': 800);

// Define Font Faces
@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
}

@font-face {
  font-family: 'Mulish';
  src: url('./fonts/Mulish-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: italic;
}

// Generate Utility Classes
@each $name, $weight in $weights {
  .font-#{to-lower-case($name)},
  .weight-#{$weight} {
    font-family: 'Mulish';
    font-weight: $weight;
  }

  .font-#{to-lower-case($name)}-italic,
  .weight-#{$weight}-italic {
    font-family: 'Mulish';
    font-weight: $weight;
    font-style: italic;
  }
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Montserrat Bold';
  src: url('./fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
}

// Font Variables
$font-regular: 'Mulish', sans-serif;
$font-semibold: 'Mulish', sans-serif;
$font-bold: 'Mulish', sans-serif;